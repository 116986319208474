import React, { useEffect, useState } from "react";
import clsx from "clsx";

/** Nextjs */
import Head from "next/head";
import dynamic from "next/dynamic";

/** MUI */
import { alpha } from "@mui/system";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/** Lottie */
import Lottie from "lottie-react";
import topAnimationData from "../../assets/lotties/update.json";
import s2AnimationData1 from "../../assets/lotties/user-profile.json";
import s2AnimationData2 from "../../assets/lotties/mobile-apps-features.json";
import s2AnimationData3 from "../../assets/lotties/pick-your-calendar.json";

/** api */
import { getAllPostsFromAPI } from "../../utils/api/posts";

/** UI */
const RequestACall = dynamic(() => import("../components/RequestACall"));
const LatestPosts = dynamic(() => import("./components/LatestPosts"));

const useStyles = makeStyles((theme) => ({
    HomePageRoot: {
        paddingTop: 96,
        height: "100%",
        display: "flex",
        position: "relative",
        "& svg": {
            opacity: 0.75,
        },
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                opacity: 0.3,
            },
        },
    },
    blursOverflow: {
        // backgroundColor: "#000", // theme.palette.primary.colors[900],
        // backgroundColor: "#041f32",
        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.colors[900]} , #000 60%, #000)`,
        overflow: "hidden",
        float: "left",
        width: "100%",
        position: "absolute",
        height: "calc(100% + 96px)",
        top: -96,
        // zIndex: -1,
        "&:before": {
            background: "url(/assets/images/XMLIDR.png) no-repeat 0px 0px",
            backgroundSize: "contain",
            width: "100%",
            content: '" "',
            float: "left",
            position: "absolute",
            left: 0,
            height: "100vh",
            top: 0,
            opacity: 0.25,
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
    },
    blurO: {
        position: "absolute",
        // width: "30vw",
        // height: "30vw",
        width: "40vw",
        height: "40vw",
        background: theme.palette.secondary.colors["A700"],
        // opacity: 0.2,
        opacity: 0.1,
        filter: "blur(40px)",
        // right: "-11vw",
        right: "-8vw",
        // top: "20vw",
        top: "30vw",
        borderRadius: "100%",
        [theme.breakpoints.down("sm")]: {
            width: 400,
            height: 400,
            right: -100,
            top: "50vh",
            opacity: 0.3,
        },
    },
    blurB: {
        position: "absolute",
        width: "50vw",
        height: "50vw",
        background: theme.palette.primary.colors["A700"],
        opacity: 0.2,
        filter: "blur(50px)",
        left: "-5vw",
        top: "-15vw",
        borderRadius: "100%",
        [theme.breakpoints.down("sm")]: {
            width: 400,
            height: 400,
            left: -100,
            top: -150,
            opacity: 0.3,
        },
    },
    card: {
        // border: "2px solid #494867",
        backdropFilter: "blur(0px)",
        borderRadius: 16,
        padding: theme.spacing(5, 4),
        background: "rgba(20,20,20,0.04)",
        position: "relative",
        zIndex: 2,
        minHeight: "75%",
        color: theme.palette.common.white,
        [theme.breakpoints.down("sm")]: {
            backdropFilter: "blur(4px)",
            background: "rgba(0,0,0,0.04)",
        },
    },
    h2: {
        color: theme.palette.common.white,
        // maxWidth: "75%",
        fontSize: "4.5rem",
        // fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "unset",
            fontSize: "2rem",
            fontWeight: 300,
        },
    },
    h2black: {
        color: theme.palette.primary.colors["900"],
    },
    topSection: {
        height: "100vh",
        // borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    whSection: {
        position: "relative",
        "& svg": {
            opacity: 0.75,
        },
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                opacity: 0.3,
            },
        },
    },
    whySection: {
        // height: "100vh",
    },
    blogSection: {
        backgroundColor: "#FFF",

        // backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 1) 25%,${alpha(
        //     theme.palette.primary.colors["900"],0.5
        // )}, rgba(255, 255, 255, 1) 75% )`,

        // backgroundImage: `linear-gradient(24deg, ${alpha(theme.palette.primary.colors["100"],0.5)} 25%, rgba(255, 255, 255, 1), ${alpha(theme.palette.primary.colors["100"], 0.5)} 75%)`,
    },
    whItem: {
        // backgroundColor: alpha(theme.palette.common.white, 0.4),
        backgroundImage: "linear-gradient(#FFF 20%, rgba(255, 255, 255, 0.025) )",
        // border: `3px solid ${theme.palette.primary.main}`,
        border: `3px solid ${theme.palette.primary.dark}`,
        backdropFilter: "blur(2px)",
        borderRadius: theme.spacing(5),
        padding: theme.spacing(4),
        marginTop: -80,
        minHeight: "calc(100% + 80px)",
        boxShadow: "0 0 50px rgba(255, 255, 255, 0.5)",
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(3, 1),
            minHeight: "calc(100% + 0px)",
        },
    },
    whTitle: {
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(4),
        borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.9950675)}`,
        textTransform: "uppercase",
        "&::first-letter": {
            fontSize: "120%",
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.common.white,
        },
    },
    whText: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
    },
    whyTitle: {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        borderBottom: `1px solid ${alpha(theme.palette.primary.colors["100"], 0.9950675)}`,
        textTransform: "uppercase",
        textAlign: "center",
        "&::first-letter": {
            fontSize: "120%",
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.common.white,
        },
    },
    whyText: {
        color: theme.palette.common.white,
        fontWeight: 200,
    },
}));

const Homepage = (props) => {
    const classes = useStyles();

    /** states */
    const [LOADING_POSTS, setLOADING_POSTS] = useState(false);
    const [POSTS, setPOSTS] = useState(null);
    const [SHOW_REQUEST, setSHOW_REQUEST] = useState(false);

    /** Effects */
    useEffect(() => {
        getAllPostsFromAPI({ limit: 5 })
            .then((result) => {
                // console.log({ result });
                setPOSTS(result);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {});
    }, []);

    /** handlers */
    const handleShowRequest = () => setSHOW_REQUEST(!SHOW_REQUEST);

    return (
        <>
            <Head>
                <meta name="description" content="Xerone solutions: The Gold Standard in Content Validation" />
                <title>{`Xerone solutions`}</title>

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://xerone.produos.dev/" />
                <meta property="og:title" content="Xerone solutions" />
                <meta property="og:description" content="The Gold Standard in Content Validation" />
                <meta property="og:image" content="https://xerone.produos.dev/xerone-og.jpg" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta property="twitter:url" content="https://xerone.produos.dev/" />
                <meta name="twitter:title" content="Xerone solutions" />
                <meta name="twitter:description" content="The Gold Standard in Content Validation" />
                <meta name="twitter:image" content="https://xerone.produos.dev/xerone-og.jpg" />

                <link rel="icon" href="/favicon.png" type="image/png" />
            </Head>

            <Box component={"section"} className={classes.topSection}>
                <Container maxWidth={false} disableGutters className={classes.HomePageRoot}>
                    <Box className={classes.blursOverflow}>
                        <Box className={classes.blurO} />
                        <Box className={classes.blurB} />
                    </Box>

                    <Container
                        maxWidth={"lg"}
                        sx={{ display: "flex", flexGrow: 1, alignItems: "center", position: "relative" }}
                    >
                        <Grid
                            container
                            spacing={3}
                            sx={{ position: "absolute", zIndex: 1, maxWidth: 1200, right: "0", top: 0 }}
                        >
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                                <Lottie animationData={topAnimationData} loop={true} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ position: "relative", zIndex: 2 }} className={classes.card}>
                            <Grid item xs={12} md={8}>
                                <Typography variant={"h2"} className={classes.h2}>
                                    {"The Gold Standard in Content Validation"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Typography variant={"h5"} sx={{ fontWeight: 100 }}>
                                    {
                                        "One solution that allows for many processes to be integrated universally into one. From software updates, mobile forensics, to data erasure, and content validation."
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    size={"large"}
                                    fullWidth
                                    onClick={handleShowRequest}
                                >
                                    {"Request A Call"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
                <RequestACall show={SHOW_REQUEST} onClose={handleShowRequest} />
            </Box>

            <Box component={"section"} className={classes.whSection}>
                <Container maxWidth={"lg"} disableGutters sx={{ position: "relative", zIndex: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Box className={classes.whItem}>
                                <Typography variant={"h4"} align={"center"} className={classes.whTitle}>
                                    {"Who"}
                                </Typography>
                                <Typography variant={"h6"} className={classes.whText}>
                                    {
                                        "We have over 20 years of experience providing expert mobile technology advice for all sectors. Our committed team brings decades of industry expertise in supply chain, and manufacturing process."
                                    }
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box className={classes.whItem}>
                                <Typography variant={"h4"} align={"center"} className={classes.whTitle}>
                                    {"What"}
                                </Typography>
                                <Typography variant={"h6"} className={classes.whText}>
                                    {
                                        "We provide one solution that allows for many processes to be integrated universally into one. From software updates, mobile forensics, to data erasure, and content validation."
                                    }
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box className={classes.whItem}>
                                <Typography variant={"h4"} align={"center"} className={classes.whTitle}>
                                    {"Where"}
                                </Typography>
                                <Typography variant={"h6"} className={classes.whText}>
                                    {
                                        "We are designed to provide strategic differentiation and operational superiority to our clients globally. We deliver CellScripter, a universal software solution that makes up for the right process, generating substantial financial efficiency."
                                    }
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mx: { xs: 1, sm: 1, md: 13 }, my: { xs: 3, sm: 3, md: 5 } }}>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                size={"large"}
                                fullWidth
                                onClick={handleShowRequest}
                            >
                                {"Tell Me More"}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth={false} disableGutters sx={{ position: "absolute", top: 25, zIndex: 0 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={3}>
                            <Lottie animationData={s2AnimationData1} loop={true} />
                        </Grid>
                        <Grid item xs={false} md={6}></Grid>
                        <Grid item xs={12} md={3}>
                            <Lottie animationData={s2AnimationData2} loop={true} />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ display: { md: "none" } }}>
                            <Lottie animationData={s2AnimationData3} loop={true} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box component={"section"} className={classes.whySection}>
                <Container maxWidth={false} disableGutters className={classes.HomePageRoot}>
                    <Box className={classes.blursOverflow} style={{ top: 0, height: "100%" }}>
                        <Box className={classes.blurO} />
                        <Box className={classes.blurB} />
                    </Box>

                    <Container maxWidth={"xl"} sx={{ mb: { xs: 3, sm: 3, md: 3 } }}>
                        <Grid container spacing={5} sx={{ position: "relative", zIndex: 2, color: "#FFF" }}>
                            <Grid item xs={12} md={12}>
                                <Typography variant={"h2"} className={classes.h2} align={"center"}>
                                    {"Why Choose Us?"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h4"} className={classes.whyTitle}>
                                    {"Experienced"}
                                </Typography>

                                <Typography variant={"h6"} className={classes.whyText}>
                                    {"We have over 20 years of experience providing expert mobile technology advice."}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h4"} className={classes.whyTitle}>
                                    {"Vibrant"}
                                </Typography>

                                <Typography variant={"h6"} className={classes.whyText}>
                                    {"A young, enthusiastic, and dynamic team of advisers are always here to help."}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h4"} className={classes.whyTitle}>
                                    {"Professional"}
                                </Typography>

                                <Typography variant={"h6"} className={classes.whyText}>
                                    {"Our technology specialists ensure you get the optimum results in little time."}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{ mx: { xs: 1, sm: 1, md: 13 }, my: { xs: 3, sm: 3, md: 5 } }}
                            >
                                <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    size={"large"}
                                    fullWidth
                                    onClick={handleShowRequest}
                                >
                                    {"Have Questions?"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>

            <Box component={"section"} className={classes.blogSection}>
                <Container maxWidth={"xl"} sx={{ mb: { xs: 3, sm: 3, md: 3 } }}>
                    <Grid container spacing={5} sx={{ position: "relative", zIndex: 2 }}>
                        <Grid item xs={12} md={12}>
                            <Typography
                                variant={"h2"}
                                className={clsx(classes.h2, classes.h2black)}
                                align={"center"}
                                sx={{ mt: { xs: 1, sm: 1, md: 5 } }}
                            >
                                {"Latest Posts"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <LatestPosts posts={POSTS} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box component={"section"} className={classes.bottomSection}>
                <Container maxWidth={false} disableGutters className={classes.HomePageRoot}>
                    <Box className={classes.blursOverflow} style={{ top: 0, height: "100%" }}>
                        <Box className={classes.blurO} />
                        <Box className={classes.blurB} />
                    </Box>

                    <Container maxWidth={"xl"} sx={{ mb: { xs: 3, sm: 3, md: 12 } }}>
                        <Grid container spacing={5} sx={{ position: "relative", zIndex: 2, color: "#FFF" }}>
                            <Grid item xs={false} md={1} />
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h4"} className={classes.whyTitle}>
                                    {"Contact"}
                                </Typography>

                                <Typography variant={"h6"} className={classes.whyText}>
                                    {"in progress..."}
                                </Typography>
                            </Grid>
                            <Grid item xs={false} md={2} />
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h4"} className={classes.whyTitle}>
                                    {"Bottom menu"}
                                </Typography>

                                <Typography variant={"h6"} className={classes.whyText}>
                                    {"in progress..."}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
        </>
    );
};

export default Homepage;
