export const getFromAPI = async (URL) => {
    try {
        const DATA = await fetch(URL).then(async (response) => {
            let result;
            try {
                result = await response.json();

                if (result.message?.type === "Error") {
                    result.message = {
                        ...result.message,
                        text: result.message.text,
                    };
                }
            } catch (e) {
                result = {
                    ...result,
                    message: {
                        type: "Error",
                        text: result?.message?.text || `Error  (${response.status})`,
                    },
                    errcode: response.status,
                };
            }
            if (!response.ok) {
                result = {
                    ...result,
                    message: {
                        type: "Error",
                        text: result?.message?.text || `Error (${response.status})`,
                    },
                    error: true,
                };
            }
            return result;
        });
        return DATA;
    } catch (error) {
        return {
            message: {
                type: "Error",
                text: `Error ${error.message ? `: "${error.message}"` : ""}`,
            },
        };
    }
};

export const postToAPI = async (URL, data = {}) => {
    if (!URL) {
        return {
            message: {
                type: "Error",
                text: `Error (missing URL)`,
            },
            error: true,
        };
    }

    try {
        const DATA = await fetch(URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
        }).then(async (response) => {
            let result;
            try {
                result = await response.json();

                if (result.message?.type === "Error") {
                    result.message = {
                        ...result.message,
                        text: result.message || result.message.text,
                    };
                }
            } catch (e) {
                result = {
                    ...result,
                    message: {
                        type: "Error",
                        text: result?.message?.text || `API says: "${response.status} ${response.statusText}"`,
                    },
                    errcode: response.status,
                };
            }
            if (!response.ok) {
                result = {
                    ...result,
                    message: {
                        type: "Error",
                        text: result?.message?.text || `API says: "${response.status} ${response.statusText}"`,
                    },
                    error: true,
                };
            }
            return result;
        });
        return DATA;
    } catch (error) {
        return {
            message: {
                type: "Error",
                text: `API says ${error.message ? `: "${error.message}"` : ""}`,
            },
        };
    }
};

export const wrongParams = (description = "") => {
    return {
        message: {
            type: "Error",
            text: `Wrong parameters for request${description ? ` (${description})` : ""}`,
        },
    };
};
